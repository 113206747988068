import { Collapse } from 'components'
import React, { useState } from 'react'

function FAQ() {
  const [ selectedKey, setSelectedKey ] = useState(null)
  
  const handleClick = (key) => {
    if(key === selectedKey){
      setSelectedKey(null)
    }else{
      setSelectedKey(key)
    }
  }

  return (
    <div className='py-[48px]'>
      <div className='container md:max-w-[882px] flex flex-col gap-5'>
        <Collapse isOpen={0 === selectedKey} onClick={() => handleClick(0)} question='Хэрхэн бүртгүүлэх вэ?'>
          <ul className='list-decimal pl-8'>
            <li>Scorebet апп-ын бусад хэсэг доторх бүртгүүлэх товч дээр дарна.</li>
            <li>Бүртгүүлэх хэсэгт өөрийн мэдээллээ үнэн зөв оруулна.</li>
            <li>Үйлчилгээний нөхцөлтэй танилцаж бүртгүүлэх товч дээр дарна.</li>
            <li>Амжилттай бүртгүүлсэн бол User name болон password оруулан нэвтрэнэ.</li>
          </ul>
        </Collapse>
        <Collapse isOpen={1 === selectedKey} onClick={() => handleClick(1)} question='Хэрхэн бооцоо тавих вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>Тоглолт хэсэгт байгаа ШУУД (live) болон СПОРТ(pre) хэсэг рүү хандана</li>
            <li>Өөрийн бооцоо тавих гэж буй спорт, лиг, тоглолт гэсэн дарааллаар хайна</li>
            <li>Санал болгож буй сонголтуудаас сонгон мөнгөн дүнгээ оруулж, бооцооны хуудасаа шалган БЭТ товч дээр дарна.</li>
          </ul>
        </Collapse>
        <Collapse isOpen={2 === selectedKey} onClick={() => handleClick(2)} question='Хэрхэн иргэний үнэмлэх баталгаажуулах вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх хувийн мэдээлэл дарж орно</li>
            <li>Баталгаажуулах хэсэгт иргэний үнэмлэхний ард болон урд талын зургийг оруулна</li>
          </ul>
        </Collapse>
        <Collapse isOpen={14 === selectedKey} onClick={() => handleClick(14)} question='Хэрхэн иргэний үнэмлэхний сельфи баталгаажуулах вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх хувийн мэдээлэл дарж орно</li>
            <li>Баталгаажуулах хэсэгт иргэний үнэмлэх барьсан сельфи зурагаа оруулна</li>
          </ul>
        </Collapse>
        <Collapse isOpen={3 === selectedKey} onClick={() => handleClick(3)} question='Хэрхэн мэйл хаяг баталгаажуулах вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх хувийн мэдээлэл дарж орно</li>
            <li>БАТАЛГААЖУУЛАЛТ хэсэг доторх И-мэйл хаяг хэсэг рүү орж код авна</li>
            <li>Таны и-мэйл хаягт илгээсэн нэг удаагийн кодыг оруулсанаар баталгаажна.</li>
          </ul>
        </Collapse>
        <Collapse isOpen={4 === selectedKey} onClick={() => handleClick(4)} question='Хэрхэн утасны дугаар баталгаажуулах вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх хувийн мэдээлэл рүү орно </li>
            <li>БАТАЛГААЖУУЛАЛТ хэсэг дэх утасны дугаар хэсэг рүү орж код авна </li>
            <li>Таны утасны дугаарт илгээсэн нэг удаагийн кодыг оруулсанаар баталгаажна.</li>
          </ul>
        </Collapse>
        <Collapse isOpen={6 === selectedKey} onClick={() => handleClick(6)} question='Хэрхэн харилцагчийн туслахтай холбогдох вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх туслах цэс рүү орно</li>
            <li>Чат болон утасны дугаар, и-мэйл гэсэн сонголтуудаас сонгож бидэнтэй холбогдох боломжтой</li>
          </ul>
        </Collapse>
        <Collapse isOpen={5 === selectedKey} onClick={() => handleClick(5)} question='Хэрхэн бооцоогоо харилцагчийн туслахад хандан шалгуулах вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх бэтийн түүх сонгож орно</li>
            <li>Өөрийн шалгуулах бооцооны ID хуулж аван харилцагийн туслахад хандана</li>
          </ul>
        </Collapse>
        {/* <Collapse isOpen={7 === selectedKey} onClick={() => handleClick(7)} question='Хэрхэн бооцоо шалгуулах вэ ?'>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et architecto, hic accusantium corporis, minus cum fuga adipisci magnam iusto nostrum iste numquam mollitia, reiciendis consequuntur sed rem odit aliquam ad!
        </Collapse> */}
        <Collapse isOpen={8 === selectedKey} onClick={() => handleClick(8)} question='Хэрхэн мөнгө татах вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх мөнгө татах хэсгийг сонгож орно</li>
            <li>Мөнгөн дүнгээ хийж хүсэлт илгээснээр татан авах боломжтой</li>
            <li>Дансны үлдэгдэл болон Татах боломжит дүнг сайтар шалгаарай</li>
          </ul>
        </Collapse>
        <Collapse isOpen={9 === selectedKey} onClick={() => handleClick(9)} question='Хэрхэн дансаар цэнэглэх вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх цэнэглэх товч дарна</li>
            <li>Өөрийн ашиглах банкаа сонгоно</li>
            <li>Гарч ирсэн дансны мэдээллийн дагуу мөнгөн дүнгээ сонгоод шилжүүлсэний дараа таны данс цэнэглэгдэнэ</li>
          </ul>
        </Collapse>
        <Collapse isOpen={13 === selectedKey} onClick={() => handleClick(13)} question='Хэрхэн QPAY цэнэглэх вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх цэнэглэх товч дарна</li>
            <li>QPAY сонгоно</li>
            <li>Цэнэглэх үнийн дүнгээ сонгоно</li>
            <li>Гарч ирж буй төлбөрийн хэрэгсэлээ сонгосоны дараа нэхэмжлэхийн дагуу шилжүүлэг хийнэ</li>
          </ul>
        </Collapse>
        <Collapse isOpen={10 === selectedKey} onClick={() => handleClick(10)} question='Хэрхэн нууц үг сэргээх вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>НУУЦ ҮГЭЭ МАРТСАН УУ? товч дээр дарна</li>
            <li>И-мэйл дээр ирсэн кодыг оруулна</li>
            <li>Шинээр нууц үгээ үүсгэн нэвтэрнэ</li>
          </ul>
        </Collapse>
        <Collapse isOpen={11 === selectedKey} onClick={() => handleClick(11)} question='Өөрийн тавьсан бооцоогоо шалгах'>
          <ul className='list-decimal pl-8'>
            <li>БУСАД хэсэг дэх Бэтийн түүх сонгож орно</li>
            <li>Хугацаа сонгосоны дараа таны бооцоо харагдана</li>
          </ul>
        </Collapse>
        <Collapse isOpen={12 === selectedKey} onClick={() => handleClick(12)} question='Slip code хэрхэн оруулах вэ ?'>
          <ul className='list-decimal pl-8'>
            <li>БЭТ хэсэг рүү орно</li>
            <li>Download bet slip дарж орно</li>
            <li>Код оруулснаар бэлэн болно</li>
          </ul>
        </Collapse>
      </div>
    </div>
  )
}

export default FAQ