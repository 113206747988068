import React from 'react'

function Button({children, className='', ...restProps}) {
  return (
    <button className={`rounded-lg overflow-hidden bg-neon text-black hover:shadow-lightning transition-all hover:brightness-105 py-3 px-6 ${className}`} {...restProps}>
      {children}
    </button>
  )
}

export default Button