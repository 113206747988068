import React from 'react'
import { FaPhone, FaEnvelope, FaFacebookF, FaInstagram, FaTelegram } from 'react-icons/fa'
import lawLogo from 'assets/images/lawLogo.png'
import iosLogo from 'assets/images/ios.svg'
import androidLogo from 'assets/images/android.svg'
import telegram from 'assets/images/Telegram.png'
import facebook from 'assets/images/Facebook.png'
import instagram from 'assets/images/Instagram.png'
import { Button } from 'components'
import { Link } from 'react-router-dom'
import androidAPK from 'assets/apk/app-release.apk'

function Footer() {
  return (
    <footer className="border-t-2 border-neon md:px-32 py-24 bg-[#091715]">
      <div className="container md:px-[200px] grid grid-cols-2 pb-24">
        <div className="flex flex-col justify-center col-span-2 md:col-span-1 mb-8 md:mb-0 gap-3 md:gap-0">
          <p className="text-neon text-3xl">Хамтарч ажиллах</p>
          <p className="text-lg text-white">И-мэйл хаягаа үлдээж хамтрагч болоорой.</p>
        </div>
        <div className="flex flex-col md:flex-row gap-6 col-span-2 md:col-span-1">
          <input className="flex-1 p-6 border border-neon rounded-lg bg-[#091715] md:text-xl outline-none placeholder:text-neon placeholder:text-opacity-40 transition-all focus:shadow-card-lightning" placeholder='И-мэйл хаягаа оруулна уу'></input>
          <Button>Нэгдэх</Button>
        </div>
      </div>
      <div className="grid grid-cols-3">
        <div className="flex flex-col mx-9 md:mx-0 gap-6 py-4 col-span-3 md:col-span-1">
          <div className="flex gap-2 items-end">
            <img alt="law logo" src={lawLogo}></img>
            <p className="text-white text-sm">Хууль зүй, дотоод <br/> хэргийн яам</p>
          </div>
          <p className='text-white max-w-[80%]'>Монгол Улсын Хууль зүй, дотоод хэргийн яамны тусгай зөвшөөрлийн дагуу үйл ажиллагаа эрхэлж буй Монголын анхны спортсбүүк юм.</p>
        </div>
        <div id='download' className="flex flex-col gap-4 py-4 justify-center items-center md:border-l md:border-r border-neon col-span-3 md:col-span-1">
          <a href={'https://apps.apple.com/mn/app/scorebet/id1660379383'} className="cursor-pointer hover:shadow-card-lightning transition-all rounded-[8px] overflow-hidden">
            <img src={iosLogo}></img>
          </a>
          <a href={androidAPK} target='_blank' className="cursor-pointer hover:shadow-card-lightning transition-all rounded-[8px] overflow-hidden">
            <img src={androidLogo}></img>
          </a>
        </div>
        <div className="flex flex-col 2xl:flex-row items-center 2xl:items-start mt-10 md:mt-0 justify-end gap-12 2xl:gap-24 col-span-3 md:col-span-1">
          <div className="flex flex-col gap-2 2xl:gap-6">
            <Link to={'/partnership'} className='hover:text-neon transition-all'>Түншлэлийн хөтөлбөр</Link>
            <a>Хамтрагч байгууллагууд</a>
            <Link to='/faq' className='hover:text-neon transition-all'>Түгээмэл асуулт хариулт</Link>
            <a>Үйлчилгээний нөхцөл</a>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-3">
              <a href="tel:70009977" className="flex items-center gap-2">
                <FaPhone color="#5EF0BB"></FaPhone>70009977
              </a>
              <a href="mailto:info@scorebet.mn" className="flex items-center gap-2">
                <FaEnvelope color="#5EF0BB"></FaEnvelope>info@scorebet.mn
              </a>
            </div>
            <div className="flex items-center justify-between">
              <a href="https://www.facebook.com/scorebet.mn" target='_blank' className='hover:shadow-lightning transition-all'>
                <FaFacebookF color='#5EF0BB' size={22}/>
              </a>
              <a href="https://www.instagram.com/scorebetofficial/" target='_blank' className='hover:shadow-lightning transition-all'>
                <FaInstagram color='#5EF0BB' size={24}/>
              </a>
              <a href="https://t.me/+43oiN1iiNmI3ZWVl" target='_blank' className='hover:shadow-lightning transition-all'>
                <FaTelegram color='#5EF0BB' size={24}/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="opacity-50 text-white text-center mt-5">
        © 2023 ScoreBet.mn | All rights reserved.
      </p>
    </footer>
  )
}

export default Footer