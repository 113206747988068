import React from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'

function Collapse({children, isOpen, onClick, question}) {
  
  return (
    <div className='flex flex-col'>
      <div className={`cursor-pointer bg-white bg-opacity-5 ${isOpen ? ' rounded-t-[6]' : 'rounded-[6px]'} py-[6px] pl-8 pr-[6px] md:text-2xl text-neon font-bold flex items-center justify-between`} onClick={onClick}>
        <div className='leading-1 select-none text-base md:text-2xl'>{question}</div>
        <div className='p-3'>
          {
            isOpen ?
            <FaMinus size={16} color='#5EF0BB'/>
            :
            <FaPlus size={16} color='#5EF0BB'/>
          }
        </div>
      </div>
      <div className={`text-[#9CEDCFD9] select-none bg-white bg-opacity-10 rounded-b-[6px] overflow-hidden text-sm md:text-lg transition-all duration-500 ${isOpen ? 'max-h-auto py-3 px-8 opacity-100' : 'max-h-0 py-0 opacity-0 px-8'}`}>
        {children}
      </div>
    </div>
  )
}

export default Collapse