import React from 'react'

function Test() {
  return (
    <div className='relative flex overflow-hidden'>
      <div className='absolute -right-[10%] h-[100px] w-[100px] bg-red-500'></div>

    </div>
  )
}

export default Test