import React from 'react'
import { FaUser } from 'react-icons/fa'

function CardBlured({icon, title='', description=''}) {
  return (
    <button className='col-span-12 md:col-span-6 lg:col-span-3 flex flex-col items-center gap-6 py-12 px-9 bg-white bg-opacity-10 backdrop-blur-md rounded-3xl hover:shadow-card-lightning transition-all'>
      {icon}
      <div>{title}</div>
      <div className='text-center text-[12] md:text-base'>
        {description}
      </div>
    </button>
  )
}

export default CardBlured