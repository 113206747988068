import { Suspense, useEffect } from 'react';
import { RouterProvider, useLocation } from 'react-router-dom';
import router from './routes';

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router}>
      </RouterProvider>
    </Suspense>
  );
}

export default App;
