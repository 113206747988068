import React, { useEffect } from 'react'

function Support() {
  useEffect(() => {
    window.addEventListener('onBitrixLiveChat', function(event)
    {
      var widget = event.detail.widget;
      widget.open()
    });
  },[])
  return (
    <div className={`w-screen h-[calc(100vh-75px)]`}>
      
    </div>
  )
}

export default Support