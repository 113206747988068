import { Button, CardBlured } from 'components'
import React from 'react'
import { FaMoneyBillTrendUp, FaHandHoldingMedical } from 'react-icons/fa6'
import cash1 from 'assets/images/cash_1.png'
import cash2 from 'assets/images/cash_2.png'
import cash3 from 'assets/images/cash_3.png'
import coin1 from 'assets/images/coin_1.png'
import coin2 from 'assets/images/coin_2.png'
import coin3 from 'assets/images/coin_3.png'
import handShake from 'assets/images/hand_shake.png'
import {ReactComponent as Arrow} from 'assets/images/Arrow.svg'
import { motion } from 'framer-motion'

function Partnership() {
  return (
    <div className='pb-16'>
      <div className='h-[calc(100vh-75px)] md:container relative flex flex-col md:flex-row justify-center items-center'>
        <div className='flex flex-col items-center md:items-start gap-6 container flex-1 mt-[15%] md:mt-0'>
          {/* max-w-[761px] */}
          <div className='md:text-[40px] relative z-40'>Бүртгүүлээд</div>
          <div className='uppercase text-neon text-3xl text-center md:text-start md:text-[64px] block relative z-40 font-sequel leading-none'>up to 50% bonus</div>
          <p className='text-sm md:text-2xl text-center md:text-start relative z-40'>
            Та ScoreBet-руу өөрийн урьсан тоглогчдыг чиглүүлснээр 50% хүртэлх ашгийн хуваарилалтыг өөрийн бүртгүүлсэн банкны дансандаа урамшуулал болгон авах боломжтой.
          </p>
          <Button className='font-bold text-2xl relative z-40'>Түншлэл</Button>
        </div>
        <div className='flex-1 aspect-square absolute w-full md:relative z-0 opacity-50 md:opacity-100'>
          <motion.div 
            animate={{y: [-5, 0, 5, 0, -5]}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3, delay: 0.9}} 
            className='absolute bottom-[10%] left-[20%] w-[60px] md:w-auto'
          >
            <img src={cash1} className='-rotate-45'/>
          </motion.div>
          <motion.div 
            animate={{y: [-5, 0, 5, 0, -5]}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3}} 
            className='absolute top-[15%] left-[30%] w-[60px] md:w-auto'
          >
            <img src={cash2}  className='-rotate-45'/>
          </motion.div>
          <motion.div 
            animate={{y: [-5, 0, 5, 0, -5]}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3, delay: 0.8}} 
            className='absolute top-[25%] right-[30%] w-[60px] md:w-auto'
          >
            <img src={cash3} className='-rotate-90'/>
          </motion.div>
          <motion.div 
            animate={{y: [-5, 0, 5, 0, -5]}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3, delay: 0.5}} 
            className='absolute top-[5%] right-[10%] w-[60px] md:w-auto'
          >
            <img src={coin1} />
          </motion.div>
          <motion.div 
            animate={{y: [-5, 0, 5, 0, -5]}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3, delay: 1.3}} 
            className='absolute top-[5%] left-[5%] w-[60px] md:w-auto'
          >
            <img src={coin2} />
          </motion.div>
          <motion.div 
            animate={{y: [-5, 0, 5, 0, -5]}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3}} 
            className='absolute top-[30%] left-[5%] w-[60px] md:w-auto'
          >
            <img src={coin3} />
          </motion.div>
          <motion.div 
            animate={{y: [-5, 0, 5, 0, -5]}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3, delay: 0.5}} 
            className='absolute inset-0 z-20 flex justify-center items-center'
          >
            <img src={handShake} />
          </motion.div>
          {/* <motion.div 
            initial={{height: 0}}
            animate={{height: 209}} 
            transition={{repeat: Infinity, ease:'linear', duration: 3, delay: 0.5}} 
            className='z-20 flex justify-center items-center h-[100px]'
          >
            <svg width="70" height="209" viewBox="0 0 70 209" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute bottom-0 right-20 z-20'>
              <path d="M56 35H14V154H56V35Z" fill="#267055"/>
              <path d="M56 159H14V179H56V159Z" fill="#267055"/>
              <path d="M56 185H14V197H56V185Z" fill="#267055"/>
              <path d="M56 203H14V209H56V203Z" fill="#267055"/>
              <path d="M70 35H0L35 0L70 35Z" fill="#267055"/>
            </svg>
          </motion.div> */}
          {/* <svg width="70" height="259" viewBox="0 0 70 259" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute bottom-0 right-0 z-20'>
            <path d="M56 35H14V154H56V35Z" fill="#267055"/>
            <path d="M56 159H14V179H56V159Z" fill="#267055"/>
            <path d="M56 185H14V197H56V185Z" fill="#267055"/>
            <path d="M56 203H14V209H56V203Z" fill="#267055"/>
            <path d="M70 35H0L35 0L70 35Z" fill="#267055"/>
          </svg>
          <svg width="70" height="209" viewBox="0 0 70 209" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute bottom-0 right-20 z-20'>
            <path d="M56 35H14V154H56V35Z" fill="#267055"/>
            <path d="M56 159H14V179H56V159Z" fill="#267055"/>
            <path d="M56 185H14V197H56V185Z" fill="#267055"/>
            <path d="M56 203H14V209H56V203Z" fill="#267055"/>
            <path d="M70 35H0L35 0L70 35Z" fill="#267055"/>
          </svg>
          <svg width="70" height="209" viewBox="0 0 70 209" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute bottom-0 right-20 z-20'>
            <path d="M56 35H14V154H56V35Z" fill="#267055"/>
            <path d="M56 159H14V179H56V159Z" fill="#267055"/>
            <path d="M56 185H14V197H56V185Z" fill="#267055"/>
            <path d="M56 203H14V209H56V203Z" fill="#267055"/>
            <path d="M70 35H0L35 0L70 35Z" fill="#267055"/>
          </svg>
          <svg width="70" height="209" viewBox="0 0 70 209" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute bottom-0 right-40 z-20'>
            <path d="M56 35H14V154H56V35Z" fill="#267055"/>
            <path d="M56 159H14V179H56V159Z" fill="#267055"/>
            <path d="M56 185H14V197H56V185Z" fill="#267055"/>
            <path d="M56 203H14V209H56V203Z" fill="#267055"/>
            <path d="M70 35H0L35 0L70 35Z" fill="#267055"/>
          </svg>
          <svg width="70" height="209" viewBox="0 0 70 209" fill="none" xmlns="http://www.w3.org/2000/svg" className='absolute bottom-0 right-60 z-20'>
            <path d="M56 35H14V154H56V35Z" fill="#267055"/>
            <path d="M56 159H14V179H56V159Z" fill="#267055"/>
            <path d="M56 185H14V197H56V185Z" fill="#267055"/>
            <path d="M56 203H14V209H56V203Z" fill="#267055"/>
            <path d="M70 35H0L35 0L70 35Z" fill="#267055"/>
          </svg> */}
        </div>

      </div>
      <div className='container flex flex-col md:flex-row justify-center gap-5'>
        <button className='max-w-[550px] flex flex-col items-center gap-6 py-12 px-5 md:px-12 bg-white bg-opacity-10 backdrop-blur-md rounded-3xl hover:shadow-card-lightning transition-all'>
          <FaMoneyBillTrendUp size={48} color='#5EF0BB'/>
          <div className='md:text-2xl'>Урамшууллын төлөлт</div>
          <div className='text-center text-[12] md:text-lg text-white text-opacity-70'>
            Сар бүр таны бүртгүүлсэн дансанд урамшуулал тооцогдоно.
          </div>
        </button>
        <button className='max-w-[550px] flex flex-col items-center gap-6 py-12 px-5 md:px-12 bg-white bg-opacity-10 backdrop-blur-md rounded-3xl hover:shadow-card-lightning transition-all'>
          <FaHandHoldingMedical size={48} color='#5EF0BB'/>
          <div className='md:text-2xl'>Урамшууллын төлөлт</div>
          <div className='text-center text-[12] md:text-lg text-white text-opacity-70'>
            24 цагийн турш техникийн асуудлаас эхлээд бизнесийн зөвлөгөө өгөх хүртэл бүх төрлийн асуудлыг түргэн шуурхай танд шийдвэрлэж өгнө.
          </div>
        </button>
      </div>
    </div>
  )
}

export default Partnership