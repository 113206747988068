import { createBrowserRouter } from "react-router-dom";
import Layout from "layouts";
import { FAQ, Home, Partnership, Support, Test } from "pages";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout/>, 
    errorElement: <div className="flex w-screan h-screen justify-center items-center">Not Found Page</div>,
    children: [
      {
        name: 'Home',
        path: '/',
        element: <Home/>,
      },
      {
        name: 'FAQ',
        path: '/faq',
        element: <FAQ/>,
      },
      {
        name: 'Partnership',
        path: '/partnership',
        element: <Partnership/>,
      },
      {
        name: 'Test',
        path: '/test',
        element: <Test/>,
      },
      {
        name: 'Support',
        path: '/support',
        element: <Support/>,
      },
    ]
  },
  // {
  //   path: '/login',
  //   errorElement: <ErrorPage/>,
  //   element: <Unauthorized></Unauthorized>
  // },
  // {
  //   path: '/401',
  //   element: <Page401/>,
  // },
  // {
  //   path: '/*',
  //   element: <Page404/>,
  // }
])

export default router
