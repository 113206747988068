import React from 'react'
import asset1 from 'assets/images/item1.png'
import { Button, CardBlured } from 'components'
import { FaCaretRight, FaDownload, FaMoneyBill, FaUpload, FaUser, FaWallet, FaHeadset, FaInfoCircle, FaQuestionCircle, FaNewspaper, FaGooglePlay } from 'react-icons/fa'
import { BsApple } from 'react-icons/bs'
import { HiOutlineCurrencyDollar } from 'react-icons/hi'
import { ReactComponent as AndroidBtn } from 'assets/images/android.svg'
import { ReactComponent as IosBtn } from 'assets/images/ios.svg'
import { ReactComponent as ScoreText } from 'assets/images/score-text.svg'
import mockup1 from 'assets/images/mockup1.png'
import mockup2 from 'assets/images/mockup2.png'
import mockup3 from 'assets/images/mockup3.png'
import mockup4 from 'assets/images/mockup4.png'
import item2 from 'assets/images/item2.png'
import item3 from 'assets/images/item3.png'
import item4 from 'assets/images/item4.png'
import item5 from 'assets/images/item5.png'
import item6 from 'assets/images/item6.png'
import statBg from 'assets/images/Statistic_background.png'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import androidAPK from 'assets/apk/app-release.apk'

function Home() {
  return (
    <div className='flex flex-col relative'>
      {/* ------------------------        Top Section        ------------------------ */}
      <div className='h-[calc(100vh-75px)] flex'>
        <div className='flex flex-col-reverse md:flex-row md:container'>
          <div className='flex-1 flex justify-center md:items-center'>
            <div className='flex flex-col gap-6 mt-6 md:mt-0 '>
              <div className='flex flex-col md:flex-row gap-1 md:gap-3 items-center'>
                <div className='text-xl md:text-3xl font-sequel'>MORE THAN</div> 
                <FaCaretRight color='#5EF0BB'/> 
                <div className="h-[28px] md:h-[36px] overflow-hidden">
                  <div className='animated-text font-sequel'>
                    <div className='text-neon text-xl md:text-3xl line'>iGaming</div>
                    <div className='text-neon text-xl md:text-3xl line'>E-SPORT</div>
                    <div className='text-neon text-xl md:text-3xl line'>Sports</div>
                    <div className='text-neon text-xl md:text-3xl line'>iGaming</div>
                    <div className='text-neon text-xl md:text-3xl line'>E-SPORT</div>
                  </div>
                </div>
              </div>
              <div className='uppercase leading-[normal] md:text-[32px] text-center md:text-start px-5 md:px-0'>
                Монголын анхны албан ёсны эрхтэй спорт бооцооны платформ <span className='text-neon'>апп</span>-тай боллоо
              </div>
              <div className='flex gap-4 justify-center md:justify-start items-center mt-6'>
                <a href={androidAPK} download target='_blank' className='hover:shadow-lightning cursor-pointer hidden md:block rounded-[8px] overflow-hidden'>
                  <AndroidBtn className='max-w-[200px]'/>
                </a>
                <a href={'https://apps.apple.com/mn/app/scorebet/id1660379383'} className='hover:shadow-lightning cursor-pointer hidden md:block rounded-[8px] overflow-hidden'>
                  <IosBtn className='max-w-[200px]'/>
                </a>
                <a target='_blank' href={'https://apps.apple.com/mn/app/scorebet/id1660379383'}>
                  <Button className='py-[14px] block md:hidden'>
                    <BsApple size={31}/>
                  </Button>
                </a>
                <a href={androidAPK} download target='_blank'>
                  <Button className='py-[14px] block md:hidden'><FaGooglePlay size={31}/></Button>
                </a>
              </div>
            </div>
          </div>
            {/* <img src={asset1} className='md:w-[80%]'/> */}
          <div 
            className='flex-1 last flex justify-center items-center relative px-6 md:px-12 md:-m-24' 
            style={{background: `url(${asset1})`, backgroundPosition:'center' , backgroundRepeat: 'no-repeat', backgroundSize: 'contain'}}
          >
            <motion.div 
              animate={{y: [-5, 0, 5, 0, -5]}} 
              transition={{repeat: Infinity, ease:'linear', duration: 3}} 
              className='md:p-[76px] absolute z-[999] flex w-[50%] md:w-auto'
            >
              <img src={mockup1}/>
            </motion.div>
          </div>
        </div>
      </div>

      {/* ------------------------        Statistics        ------------------------ */}

      <div className='h-[calc(100vh-75px)] flex justify-center items-center object-cover object-center bg-no-repeat' style={{backgroundImage: `url(${statBg})`}}>
        <div className='gap-[48px] flex flex-col items-center'>
          <div className='text-neon text-2xl md:text-3xl'>Манай платформд</div>
          <div className='flex flex-col md:flex-row items-center gap-[64px]'>
            <div className='flex flex-col items-center'>
              <div className='text-6xl md:text-[100px] leading-[normal]'>41+</div>
              <div>Төрлийн спортууд</div>
            </div>
            <div className='flex flex-col items-center'>
              <div className='text-6xl md:text-[100px] leading-[normal]'>149K+</div>
              <div>Тэмцээнүүд</div>
            </div>
            <div className='flex flex-col items-center'>
              <div className='text-6xl md:text-[100px] leading-[normal]'>2.5K+</div>
              <div>Маркетууд</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* ------------------------        Features        ------------------------ */}

      <div className='relative'>
        <div className='gap-[45px] flex flex-col items-center container py-16 relative z-50'>
          <div className='text-neon text-3xl'>Манай платформд</div>
          <div className='grid grid-cols-12 gap-5'>
            <CardBlured 
              icon={<FaUser size={42} color='#5EF0BB'/>}
              title='Бүртгүүлэх'
              description='Өөрийн хувийн мэдээллээ оруулан, үйлчилгээний нөхцөлтэй танилцаж бүртгэлээ баталгаажуулаарай.'
            />
            <CardBlured 
              icon={<FaDownload size={42} color='#5EF0BB'/>}
              title='Данс цэнэглэлт'
              description='Та банкныхаа дансыг манай системд холбосноор хугацаа алдалгүй шууд хийх боломжтой.'
            />
            <CardBlured 
              icon={<FaMoneyBill size={42} color='#5EF0BB'/>}
              title='Бооцоо тавих'
              description='Та өөрийн хүссэн спортын төрлийн хожлын сонголтуудаас сонгож бооцоо тавих боломжтой.'
            />
            <CardBlured 
              icon={<FaUpload size={42} color='#5EF0BB'/>}
              title='Таталт хийх'
              description='Та холбосон данс руугаа хугацаа алдалгүй мөнгө таталт хийх боломжтой.'
            />
          </div>
        </div>
        <img className='absolute -left-[40%] top-[15%] md:-left-20 md:-bottom-32' src={item4}/>
      </div>

      {/* ------------------------        Why Scorebet        ------------------------ */}

      <div className='relative py-[48px] flex flex-col items-center gap-[38px] md:gap-[48px]'>
        <div className='text-neon text-[24px] md:text-[40px] flex items-end gap-4 md:leading-none'>Яагаад <ScoreText className='w-[130px] md:w-auto'/> гэж ?</div>
        <div className='gap-[55px] relative z-50'>
          <div className='container md:px-[200px] flex flex-col md:flex-row items-center justify-between mb-[142px]'>
            <div className='flex flex-col items-center md:items-start max-w-[690px] md:-mr-24'>
              <FaWallet color='#5EF0BB' size={75}/>
              <div className='text-3xl py-3'>Дансны <span className='text-neon'>удирдлага</span></div>
              <div className='text-lg text-center md:text-start mb-10 md:mb-0'>Та өөрийн дансаа 100% удирдан данс цэнэглэлт болон мөнгө таталтыг түргэн шуурхай хийх боломжтой.</div>
            </div>
            <img src={mockup2} className='md:-mr-[20%]'/>
          </div>
        </div>
        <img src={item3} className='absolute -bottom-[0] w-1/2 md:w-auto md:top-0 right-0'/>
      </div>

      {/* ------------------------        Bonus        ------------------------ */}

      <div className='relative'>
        <div className='gap-[55px] relative z-50'>
          <div className='container md:px-[200px] flex flex-col md:flex-row-reverse items-center justify-between mb-[142px]'>
            <div className='flex flex-col items-center md:items-end'>
              <HiOutlineCurrencyDollar color='#5EF0BB' size={75}/>
              <div className='text-3xl py-3'>Бонус</div>
              <div className='text-[18px] font-normal text-center md:text-end mb-10 md:mb-0'>Бид танд оддс бонус, нэг алдсаны бонус, анхны цэнэглэлтийн бонус гэх мэт олон төрлийн бонусыг санал болгохоос гадна та өөрийн промо кодыг үүсгэн тухайн кодоороо найзуудаа урьж урамшуулал авах боломжтой.</div>
            </div>
            <img src={mockup4} className='flex-1 md:ml-[-20%]'/>
          </div>
        </div>
        <img className='absolute hidden md:flex -top-[25%] -left-[20%] max-w-[750px] w-[45%] aspect-square bg-neon rounded-full' src={item6}></img>
        <motion.div 
          animate={{rotate: [-20 , 90, -20]}} 
          transition={{repeat: Infinity, ease:'linear', duration: 13}} 
          className='absolute hidden md:flex -bottom-[0] z-50 md:-bottom-[10%] left-0 md:-left-[10%] w-[30%] aspect-square'
        >
          <img src={item5}/>
        </motion.div>
      </div>

      {/* ------------------------        Support        ------------------------ */}

      <div className='relative py-[48px] flex flex-col items-center gap-[38px] md:gap-[48px] overflow-x-hidden'>
        <div className='gap-[55px] relative z-50'>
          <div className='container  md:px-[200px] flex flex-col md:flex-row items-center justify-between mb-[142px]'>
            <div className='flex flex-col items-center md:items-start max-w-[690px] md:-mr-24'>
              <FaHeadset color='#5EF0BB' size={75}/>
              <p className='text-4xl py-3 text-center md:text-start'>Харилцагчийн туслах</p>
              <div className='text-lg text-center md:text-start mb-10 md:mb-0'>Хэрэв танд тусламж хэрэгтэй бол манай хэрэглэгчийн төв танд 24/7 мэдээлэл өгч, асуудлыг шийдвэрлэн ажиллаж байна.</div>
            </div>
            <img src={mockup3} className='w-full md:-mr-[30%] '/>
          </div>
        </div>
      </div>

      {/* ------------------------        last section        ------------------------ */}

      <div className="relative ">
        <div className="container md:px-[200px] py-14 grid grid-cols-3 justify-between gap-6">
          <a 
            target='_blank' 
            href='https://temppartners.mn/' 
            className="col-span-3 md:col-span-1 pt-12 pl-12 pr-9 pb-8 rounded-2xl bg-[#ffffff1a] backdrop-blur-md hover:shadow-card-lightning transition-all cursor-pointer"
          >
            <FaInfoCircle color="#5EF0BB" size={48} className="mb-6"></FaInfoCircle>
            <p className="text-2xl mb-6 text-white">Бидний тухай</p>
            <p className="text-[#FFFFFFB2]">Манай байгууллагын тухай дэлгэрэнгүй мэдээлэлтэй танилцана уу.</p>
          </a>
          <Link to={'/faq'} className='col-span-3 md:col-span-1 flex'>
            <div className="pt-12 pl-12 pr-9 pb-8 rounded-2xl bg-[#ffffff1a] backdrop-blur-md hover:shadow-card-lightning transition-all cursor-pointer">
              <FaQuestionCircle color="#5EF0BB" size={48} className="mb-6"></FaQuestionCircle>
              <p className="text-2xl mb-6 text-white">FAQ</p>
              <p className="text-[#FFFFFFB2]">Танд ямар нэгэн асуулт, илүү мэдээлэл хэрэгтэй бол түгээмэл асуулт хариулттай танилцана уу.</p>
            </div>
          </Link>
          <a 
            target='_blank' 
            href='https://www.facebook.com/Scorenewsmng' 
            className="col-span-3 md:col-span-1 pt-12 pl-12 pr-9 pb-8 rounded-2xl bg-[#ffffff1a] backdrop-blur-md hover:shadow-card-lightning transition-all cursor-pointer"
          >
            <FaNewspaper color="#5EF0BB" size={48} className="mb-6"></FaNewspaper>
            <p className="text-2xl mb-6 text-white">S News</p>
            <p className="text-[#FFFFFFB2]">Манай хуудсаар зочилсноор та мэргэжлийн спортын тухай мэдээ мэдээллийг цаг алдалгүй авах болно.</p>
          </a>
        </div>
        <img src={item2} className="absolute right-0 bottom-0 -z-[1]">
        </img>
      </div>
    </div>
  )
}

export default Home