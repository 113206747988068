import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import logoIcon from 'assets/images/logo-icon.png'
import { FaBars, FaPlus } from 'react-icons/fa'
import { motion } from 'framer-motion'

function Header() {
  const [ isOpenBurgerMenu, setIsOpenBurgerMenu ] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if(isOpenBurgerMenu){
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpenBurgerMenu(!isOpenBurgerMenu);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const variants = {
    open: { opacity: 1, left: 0 },
    closed: { opacity: 0, left: "100%", },
  }

  return (
    <div className='header flex w-full'>
      <div className='relative flex-1 items-center flex justify-between px-4 md:px-[48px]'>
        <Link to={'/'} className='hidden md:flex'>
          <img src={logo}/>
        </Link>
        <div className='hidden md:flex gap-9 items-center'>
          <div className='flex gap-2 items-center'>
            {/* <button className='hover:text-neon transition-all'>MN</button>
            <div>|</div> */}
            <Link to={'/partnership'} className='hover:text-neon transition-all'>Түншлэл</Link>
          </div>
          <Link className='hover:text-neon transition-all'>Хамтран ажиллах</Link>
          <Link to={'/faq'} className='hover:text-neon transition-all'>FAQ</Link>
          <a href='#download' className='hover:text-neon transition-all border border-neon rounded-[6px] py-4 px-6'>Aпп татах</a>
        </div>
        <Link to={'/'} className='flex md:hidden'>
          <img src={logoIcon}/>
        </Link>
        <button className='flex md:hidden' onClick={() => setIsOpenBurgerMenu(!isOpenBurgerMenu)}>
          <FaBars size={24} className='text-neon'/>
        </button>
        <motion.div
          animate={isOpenBurgerMenu ? 'open' : 'closed'}
          variants={variants}
          className='absolute left-[100%] top-0 w-[100vw] z-[999999]'
          transition={{ease: 'easeOut'}}
          ref={ref}
        >
          <div className='flex flex-col items-center gap-6 bg-[#091615] relative py-4'>
            <Link onClick={() => setIsOpenBurgerMenu(false)} to={'/partnership'}>Түншлэл</Link>
            <Link onClick={() => setIsOpenBurgerMenu(false)}>Хамтарч ажиллах</Link>
            <Link to={'/faq'} onClick={() => setIsOpenBurgerMenu(false)}>FAQ</Link>
            <Link className='text-neon' onClick={() => setIsOpenBurgerMenu(false)}>Апп татах</Link>
            <button className='absolute right-5 text-neon' onClick={() => setIsOpenBurgerMenu(false)}>
              <FaPlus size={24} className='rotate-45'/>
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Header