import React, { useEffect } from 'react'
import Header from './Header'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from './Footer'

function Layout() {

  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }, [pathname]);
  
    return null;
  }

  return (
    <div>
      <Header />
      <div className='pt-[75px]'>
        <Outlet/>
      </div>
      <Footer/>
      <ScrollToTop/>    
    </div>
  )
}

export default Layout